import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NiceLeaderboardData } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class NiceLeaderboardService {
  constructor(private http: HttpClient) {}

  getData(exerciseId: string): Observable<NiceLeaderboardData> {
    return this.http
      .get<NiceLeaderboardData>(`${NiceLeaderboardService.getBaseUrl(exerciseId)}`)
      .pipe(map((data) => new NiceLeaderboardData(data)));
  }

  private static getBaseUrl(exerciseId: string): string {
    return `api/ctf-exercises/${exerciseId}/nice-leaderboard`;
  }
}
