<cybexer-widget-header [title]="'ui.attackCampaign.attackCampaign' | translate" [inline]="true">
  <div class="spacer"></div>
  <cybexer-row class="same-line">
    <cybexer-meta-item
      [icon]="'fas fa-users'"
      [matTooltip]="'ui.attackCampaign.clientSide' | translate | uppercase"
    >
      {{ 'ui.attackCampaign.clientSide' | translate }}
    </cybexer-meta-item>
    <cybexer-meta-item
      [icon]="'fas fa-sitemap'"
      [matTooltip]="'ui.attackCampaign.networking' | translate | uppercase"
    >
      {{ 'ui.attackCampaign.networking' | translate }}
    </cybexer-meta-item>
    <cybexer-meta-item
      [icon]="'fas fa-server'"
      [matTooltip]="'ui.attackCampaign.web' | translate | uppercase"
    >
      {{ 'ui.attackCampaign.web' | translate }}
    </cybexer-meta-item>
  </cybexer-row>
  <div class="newline"></div>
  <mat-slide-toggle
    [ngModel]="(filter$ | async).highlightAssignments"
    (ngModelChange)="filterStateService.setFilter('highlightAssignments', $event)"
  >
    {{ 'ui.attackCampaign.highlightAssignments' | translate }}
  </mat-slide-toggle>
  <isa-filtered-select
    [options]="teams"
    [multiple]="true"
    [preselectedOptions]="(filter$ | async).teams"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [label]="'ui.attackCampaign.filterByTeam' | translate"
    (selectionChange)="filterStateService.setFilter('teams', $event)"
  ></isa-filtered-select>
  <mat-form-field class="campaign-phase-filter-dropdown">
    <mat-label>{{ 'ui.attackCampaign.campaignPhase' | translate }}</mat-label>
    <mat-select
      [ngModel]="(filter$ | async).campaignPhase"
      (ngModelChange)="filterStateService.setFilter('campaignPhase', $event)"
    >
      @for (campaignPhase of exercise?.campaignPhases; track campaignPhase) {
        <mat-option [value]="campaignPhase.id">
          {{ campaignPhase.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="objective-category-filter-dropdown">
    <mat-label>{{ 'ui.attackCampaign.filterByCategory' | translate }}</mat-label>
    <mat-select
      multiple
      [ngModel]="(filter$ | async).objectiveCategories"
      (ngModelChange)="filterStateService.setFilter('objectiveCategories', $event)"
    >
      <mat-option value="WEB">
        <i class="fas fa-server" aria-hidden="true"></i>
        {{ 'ui.attackCampaign.web' | translate }}
      </mat-option>
      <mat-option value="NETWORKING">
        <i class="fas fa-sitemap" aria-hidden="true"></i>
        {{ 'ui.attackCampaign.networking' | translate }}
      </mat-option>
      <mat-option value="CLIENT_SIDE">
        <i class="fas fa-users" aria-hidden="true"></i>
        {{ 'ui.attackCampaign.clientSide' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label
      ><i class="fas fa-filter" aria-hidden="true"></i>
      {{ 'ui.attackCampaign.objective' | translate }}</mat-label
    >
    <input
      autocomplete="off"
      matInput
      [formControl]="filterText"
      (keydown.enter)="$event.preventDefault()"
    />
  </mat-form-field>
</cybexer-widget-header>
<cybexer-widget-body class="attack-campaign">
  <mat-accordion class="isa-content-accordion" multi="false">
    @for (
      objective of objectives | nameFilter: filterText.value;
      let i = $index;
      track objective.trackBy
    ) {
      <mat-expansion-panel
        class="attack-campaign-expansion-panel"
        hideToggle
        (opened)="expandedObjectiveIndex = i"
      >
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="58px">
          <div class="accordion-title">
            <div class="icon">
              @if (objective?.category === 'CLIENT_SIDE' && objective.type !== 'TARGET_GROUP') {
                <span>
                  <i
                    class="fas fa-users"
                    aria-hidden="true"
                    [matTooltip]="'ui.attackCampaign.clientSide' | translate | uppercase"
                  ></i>
                </span>
              }
              @if (objective?.category === 'CLIENT_SIDE' && objective.type === 'TARGET_GROUP') {
                <span
                  [matTooltip]="'ui.attackCampaign.clientSide' | translate | uppercase"
                  class="target-group-objective"
                >
                  <img
                    [src]="
                      isLightTheme
                        ? '../../../../assets/svg/client-group-light-theme.svg'
                        : '../../../../assets/svg/client-group.svg'
                    "
                    class="svg-icon"
                  />
                </span>
              }
              @if (objective?.category === 'NETWORKING' && objective.type !== 'TARGET_GROUP') {
                <span>
                  <i
                    class="fas fa-sitemap"
                    aria-hidden="true"
                    [matTooltip]="'ui.attackCampaign.networking' | translate | uppercase"
                  ></i>
                </span>
              }
              @if (objective?.category === 'NETWORKING' && objective.type === 'TARGET_GROUP') {
                <span
                  [matTooltip]="'ui.attackCampaign.networking' | translate | uppercase"
                  class="target-group-objective"
                >
                  <img
                    [src]="
                      isLightTheme
                        ? '../../../../assets/svg/network-group-light-theme.svg'
                        : '../../../../assets/svg/network-group.svg'
                    "
                    class="svg-icon"
                  />
                </span>
              }
              @if (objective?.category === 'WEB' && objective.type !== 'TARGET_GROUP') {
                <span>
                  <i
                    class="fas fa-server"
                    aria-hidden="true"
                    [matTooltip]="'ui.attackCampaign.web' | translate | uppercase"
                  ></i>
                </span>
              }
              @if (objective?.category === 'WEB' && objective.type === 'TARGET_GROUP') {
                <span
                  [matTooltip]="'ui.attackCampaign.web' | translate | uppercase"
                  class="target-group-objective"
                >
                  <img
                    [src]="
                      isLightTheme
                        ? '../../../../assets/svg/web-group-light-theme.svg'
                        : '../../../../assets/svg/web-group.svg'
                    "
                    class="svg-icon"
                  />
                </span>
              }
            </div>
            <div class="disable-uppercase objective">
              <div class="objective-text">
                {{ objective.name }}
              </div>
              <div class="status-square-container">
                @for (team of objective.teams; track team) {
                  <div
                    [ngClass]="{
                      'in-progress': team.objectiveUserName && !isLightTheme,
                      'in-progress-light-theme': team.objectiveUserName && isLightTheme,
                      compromised: team.objectiveStatus === OBJECTIVE_STATUS.COMPROMISED,
                      'pending-compromised':
                        team.objectiveStatus === OBJECTIVE_STATUS.PENDING_COMPROMISED,
                      'unable-to-compromise':
                        team.objectiveStatus === OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE
                    }"
                    class="status-square"
                    [matTooltip]="
                      (team.teamId | isaTeamName) +
                      ' - ' +
                      (team.objectiveUserName ? team.objectiveUserName + ' - ' : '') +
                      ('ui.attackCampaign.' + (team.objectiveStatus | lowercase) | translate)
                    "
                  >
                    @if (!team.targetCheckAvailabilityStatus) {
                      <span
                        class="not-available"
                        [ngClass]="{ 'not-available-light': !team.objectiveUserName }"
                        >x</span
                      >
                    }
                  </div>
                }
              </div>
            </div>
            @if (!isWhiteTeam) {
              <div class="buttons-container">
                <button
                  (click)="$event.stopPropagation(); openBatchStatusReport(objective)"
                  [disabled]="!isAnyObjectiveAssignedToCurrentUser(objective) || !isExerciseRunning"
                  data-testid="report-all-btn"
                  mat-button
                >
                  <i class="fas fa-flag"></i>{{ 'ui.attackCampaign.reportAll' | translate }}
                </button>
                <button
                  (click)="$event.stopPropagation(); assignAllObjectiveTeams(objective)"
                  [disabled]="
                    isEveryObjectiveAssignedToCurrentUser(objective) || !isExerciseRunning
                  "
                  data-testid="assign-all-btn"
                  mat-button
                >
                  <mat-icon>group_add</mat-icon>{{ 'ui.attackCampaign.assignAll' | translate }}
                </button>
              </div>
            }
          </div>
        </mat-expansion-panel-header>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight="true">
          <mat-tab [label]="'ui.attackCampaign.overview' | translate">
            @if (objective?.mitreTags) {
              <isa-mitre-container [tags]="objective?.mitreTags"></isa-mitre-container>
            }
            <div class="description">
              {{ 'ui.attackCampaign.description' | translate }}
              <div class="description-content disable-uppercase">{{ objective.name }}</div>
            </div>
            @if (expandedObjectiveIndex === i) {
              <div class="team-status-container">
                @for (teamStatus of objective.teams; track teamStatus) {
                  <div class="team-status" data-testid="team-status">
                    <div class="team-name">{{ teamStatus.teamId | isaTeamName }}</div>
                    <div class="team-status-label disable-uppercase availability">
                      <i
                        class="fas"
                        [ngClass]="
                          teamStatus.targetCheckAvailabilityStatus
                            ? 'fa-check-circle status--available'
                            : 'fa-minus-circle status--not-available'
                        "
                      ></i>
                      {{
                        (teamStatus.targetCheckAvailabilityStatus
                          ? 'ui.attackCampaign.available'
                          : 'ui.attackCampaign.notAvailable'
                        ) | translate
                      }}
                    </div>
                    <div>
                      @if (teamStatus.objectiveUserName) {
                        <div class="team-status-label disable-uppercase">
                          <i class="fas fa-user"></i> {{ teamStatus.objectiveUserName }}
                        </div>
                      }
                      <div class="team-status-label">
                        <isa-objective-status-icon
                          [status]="teamStatus.objectiveStatus"
                        ></isa-objective-status-icon>
                        {{
                          'ui.attackCampaign.' + (teamStatus.objectiveStatus | lowercase)
                            | translate
                        }}
                      </div>
                    </div>
                    @if (teamStatus) {
                      <div
                        class="statuses__button actions"
                        [ngClass]="{
                          'statuses__button--assigned':
                            (filter$ | async).highlightAssignments &&
                            isTeamAssignedToCurrentUser(teamStatus)
                        }"
                      >
                        @if (!isWhiteTeam) {
                          <div>
                            <button
                              mat-button
                              [disabled]="
                                !isTeamAssignedToCurrentUser(teamStatus) || !isExerciseRunning
                              "
                              [attr.aria-label]="
                                'ui.attackCampaign.objectiveStatus'
                                  | translate: { status: teamStatus.objectiveStatus }
                              "
                              (click)="openStatusReport(teamStatus, objective)"
                              [matTooltip]="
                                teamStatus.reportCount
                                  ? ('ui.attackCampaign.reportsSubmitted'
                                    | translate: { count: teamStatus.reportCount })
                                  : ''
                              "
                            >
                              <i class="fas fa-flag"></i>
                              <span>{{ 'ui.attackCampaign.report' | translate }}</span>
                              @if (teamStatus.reportCount) {
                                <span> ({{ teamStatus.reportCount }})</span>
                              }
                            </button>
                            @if (
                              teamStatus.objectiveUserName &&
                              !isTeamAssignedToCurrentUser(teamStatus)
                            ) {
                              <button
                                (click)="reassignObjective(teamStatus, objective)"
                                mat-button
                                data-testid="reassign-btn"
                                [disabled]="!isExerciseRunning"
                              >
                                <i class="fas fa-user" [ngClass]="getUserClass(teamStatus)"></i>
                                {{ 'ui.attackCampaign.reassign' | translate }}
                              </button>
                            }
                            <cybexer-confirm-action
                              [disabled]="!isExerciseRunning"
                              data-testid="confirm-wrapper"
                            >
                              @if (!teamStatus.objectiveUserName) {
                                <button
                                  (click)="assignObjective(teamStatus)"
                                  mat-button
                                  data-testid="assign-btn"
                                  [disabled]="!isExerciseRunning"
                                >
                                  <i
                                    class="fas fa-user-plus"
                                    [ngClass]="getUserClass(teamStatus)"
                                  ></i>
                                  {{ 'ui.assign' | translate }}
                                </button>
                              }
                              @if (isTeamAssignedToCurrentUser(teamStatus)) {
                                <button
                                  (click)="unassignObjective(teamStatus)"
                                  mat-button
                                  data-testid="unassign-btn"
                                  [disabled]="!isExerciseRunning"
                                >
                                  <i
                                    class="fas fa-user-times"
                                    [ngClass]="getUserClass(teamStatus)"
                                  ></i>
                                  {{ 'ui.attackCampaign.unassign' | translate }}
                                </button>
                              }
                            </cybexer-confirm-action>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </mat-tab>
          @if (objective?.technicalDetails) {
            <mat-tab [label]="'ui.attackCampaign.technicalDetails' | translate">
              <div class="md-content-text disable-uppercase">
                <markdown [data]="objective.technicalDetails"></markdown>
              </div>
            </mat-tab>
          }
          @if (objective?.solution) {
            <mat-tab [label]="'ui.attackCampaign.solution' | translate">
              <div class="md-content-text disable-uppercase">
                <markdown [data]="objective.solution"></markdown>
              </div>
            </mat-tab>
          }
        </mat-tab-group>
      </mat-expansion-panel>
    }
  </mat-accordion>
  @if (!attackCampaignDataList?.length && !loading) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</cybexer-widget-body>
