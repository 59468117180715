<div class="incident-report-confirm-dialog">
  <h2 matDialogTitle>{{ 'ui.reportingStatus.incidentReports' | translate }}</h2>
  <mat-dialog-content class="incident-report-confirm-dialog-content">
    @if (!loaded) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
    <table class="isa-table">
      <thead>
        <tr>
          <th>{{ 'ui.situationReport.time' | translate }}</th>
          <th>{{ 'ui.incidentReport.target' | translate }}</th>
          <th>{{ 'ui.incidentReport.incidentType' | translate }}</th>
          <th>{{ 'ui.incidentReport.details' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        @for (incidentReport of reportingStatusIncidentReports; track incidentReport) {
          <tr>
            <td>{{ incidentReport.timestamp | isaDate: 'medium' }}</td>
            <td>{{ incidentReport.targetId | isaTargetName: exercise }}</td>
            <td>{{ incidentReport.incidentType | isaIncidentTypeName | translate }}</td>
            <td>
              <quill-editor
                [ngModel]="incidentReport.details"
                [readOnly]="true"
                [modules]="{ toolbar: false }"
                ngDefaultControl
              ></quill-editor>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>{{ 'ui.close' | translate }}</button>
  </mat-dialog-actions>
</div>
