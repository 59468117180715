import { BaseModel } from '../../shared/base.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class NiceItemsCategory extends BaseModel {
  name: string;
  solved: number;
  unsolved: number;
  total: number;
  items: NiceItem[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('items')) {
      this.items = data.items.map((item) => new NiceItem(item));
    }
  }
}

export class NiceItem extends BaseModel {
  id: string;
  solved: number;
  unsolved: number;
  tasks: NiceItemTask[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('tasks')) {
      this.tasks = data.tasks.map((task) => new NiceItemTask(task));
    }
  }
}

export class NiceItemTask extends BaseModel {
  title: string;
  status: CTFTaskStatus;
  category: string;
  isSolved: boolean;

  constructor(data?) {
    super(data);
  }
}

export class NiceLeaderboardData extends BaseModel {
  overview: NiceScoreBreakdown;
  teams: NiceLeaderboardTeamData[];

  constructor(data) {
    super(data);

    if (data && data.hasOwnProperty('overview')) {
      this.overview = new NiceScoreBreakdown(data.overview);
    }

    if (data && data.hasOwnProperty('teams')) {
      this.teams = data.teams.map((team) => new NiceLeaderboardTeamData(team));
    }
  }
}

export class NiceLeaderboardTeamData extends BaseModel {
  id: string;
  name: string;
  score: number;
  scoreBreakdown: NiceScoreBreakdown;
  userSolvedItems: NiceLeaderboardUserData[];
  allNiceItems: NiceItem[];

  constructor(data) {
    super(data);

    if (data && data.hasOwnProperty('scoreBreakdown')) {
      this.scoreBreakdown = new NiceScoreBreakdown(data.scoreBreakdown);
    }

    if (data && data.hasOwnProperty('userSolvedItems')) {
      this.userSolvedItems = data.userSolvedItems.map((it) => new NiceLeaderboardUserData(it));
    }

    if (data && data.hasOwnProperty('allNiceItems')) {
      this.allNiceItems = data.allNiceItems.map((it) => new NiceItem(it));
    }
  }
}

export class NiceScoreBreakdown extends BaseModel {
  total: number;
  abilities: number;
  knowledge: number;
  skills: number;
  tasks: number;

  constructor(data) {
    super(data);
  }
}

export class NiceLeaderboardUserData extends BaseModel {
  username?: string;
  items: NiceItem[];

  constructor(data) {
    super(data);
  }
}
