import { CommonModule, SlicePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { NgChartsModule } from 'ng2-charts';
import { IsaPipesModule } from '../../../pipes';
import { ExerciseDurationSliderComponent } from './exercise-duration-slider/exercise-duration-slider.component';
import { MitreContainerComponent } from './mitre-container/mitre-container.component';
import { NiceCategoriesContainerComponent } from './nice/nice-categories-container/nice-categories-container.component';
import { NiceContainerComponent } from './nice/nice-container/nice-container.component';
import { ScoringChartsComponent } from './scoring-charts/scoring-charts.component';
import { StatusSummaryChartComponent } from './status-summary-chart/status-summary-chart.component';
import { TeamSelectComponent } from './team-select/team-select.component';
import { MatIconModule } from '@angular/material/icon';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { TranslateModule } from '@ngx-translate/core';
import { NiceItemComponent } from './nice/nice-item/nice-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatListModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    NgChartsModule,
    IsaPipesModule,
    FormsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSliderModule,
    MatBadgeModule,
    NgxCommonsModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [
    StatusSummaryChartComponent,
    ScoringChartsComponent,
    ExerciseDurationSliderComponent,
    TeamSelectComponent,
    MitreContainerComponent,
    NiceContainerComponent,
    NiceItemComponent,
    NiceCategoriesContainerComponent,
    StarRatingComponent,
  ],
  declarations: [
    StatusSummaryChartComponent,
    ScoringChartsComponent,
    ExerciseDurationSliderComponent,
    TeamSelectComponent,
    MitreContainerComponent,
    NiceContainerComponent,
    NiceItemComponent,
    NiceCategoriesContainerComponent,
    StarRatingComponent,
  ],
  providers: [SlicePipe],
})
export class SharedModule {}
