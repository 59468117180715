import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import {
  AttackCampaignData,
  AttackCampaignDataObjective,
  AttackCampaignDataObjectiveTeam,
  AttackCampaignReport,
  BlueTeam,
  Exercise,
  ExerciseStatus,
  User,
  UserAssignment,
} from '../../../models';
import {
  AttackCampaignService,
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
} from '../../../services';
import {
  FilterStateModel,
  FilterStateService,
  OBJECTIVE_STATUS,
  ObjectiveStatus,
  ROLES,
} from '../../../shared';
import {
  MultiAssignDialogComponent,
  MultiAssignInput,
  MultiAssignResult,
} from './multi-assign-dialog/multi-assign-dialog.component';
import {
  MultiReportDialogComponent,
  MultiReportInput,
  MultiReportResult,
} from './multi-report-dialog/multi-report-dialog.component';
import { ObjectiveStatusReportComponent } from './objective-status-report/objective-status-report.component';
import {
  AssignmentResult,
  UserAssignmentDialogComponent,
} from './user-assignment-dialog/user-assignment-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-attack-campaign',
  templateUrl: './attack-campaign.component.html',
  styleUrls: ['./attack-campaign.component.scss'],
})
export class AttackCampaignComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  currentUser: User;
  loading = false;
  expandedObjectiveIndex: number;
  filterText: UntypedFormControl;
  attackCampaignDataList: AttackCampaignData[] = [];
  objectives: AttackCampaignDataObjective[] = [];
  statusReportDialogRef: MatDialogRef<ObjectiveStatusReportComponent>;
  assignDialogRef: MatDialogRef<UserAssignmentDialogComponent, AssignmentResult>;
  multiAssignDialogRef: MatDialogRef<MultiAssignDialogComponent, MultiAssignResult>;
  multiReportDialogRef: MatDialogRef<MultiReportDialogComponent, MultiReportResult>;
  OBJECTIVE_STATUS = OBJECTIVE_STATUS;
  isLightTheme: boolean;
  isWhiteTeam = true;
  isExerciseRunning: boolean;

  constructor(
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private attackCampaignService: AttackCampaignService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private preferenceService: PreferenceService,
    private dialog: MatDialog,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit(): void {
    this.filter$ = this.filterStateService.filter$(
      'highlightAssignments',
      'teams',
      'campaignPhase',
      'objectiveCategories'
    );
    this.currentUser = this.authenticationService.currentUser;
    this.loading = true;
    this.filterText = new UntypedFormControl('', Validators.maxLength(100));
    this.exerciseService.activeExercise
      .pipe(
        untilDestroyed(this),
        tap((exercise: Exercise) => {
          this.attackCampaignDataList = [];
          this.exercise = exercise;

          if (exercise) {
            this.isWhiteTeam = this.authenticationService.getRole(exercise.id) === ROLES.WHITE;
            this.teams = exercise.blueTeams;

            // Get all objectives for all teams
            if (exercise.campaignPhases && exercise.campaignPhases.length) {
              this.filterStateService.setFilterIfEmptyOrDefault(
                'campaignPhase',
                exercise.campaignPhases[0].id
              );
            }

            this.filterStateService.setFilterIfEmptyOrDefault(
              'teams',
              exercise.blueTeams.map((blueTeam) => blueTeam.id)
            );
          }
        }),
        switchMap((exercise: Exercise) => {
          if (exercise) {
            return this.exerciseService.isExerciseRunning(exercise.id).pipe(
              untilDestroyed(this),
              tap((isRunning: boolean) => {
                this.isExerciseRunning = isRunning;
              })
            );
          } else {
            this.isExerciseRunning = false;
            return of(null);
          }
        })
      )
      .subscribe();

    this.intervalService
      .getWidgetRefreshInterval()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchCampaignData();
      });

    this.preferenceService
      .getPreferences()
      .pipe(first())
      .subscribe((pref) => (this.isLightTheme = pref.isLightTheme));

    this.filter$.pipe(untilDestroyed(this)).subscribe(() => {
      this.fetchCampaignData();
    });
  }

  filterObjectives() {
    if (this.attackCampaignDataList == null || this.attackCampaignDataList.length === 0) {
      return;
    }

    this.filterObjectivesByCategory();
    this.filterObjectivesByTeams();
  }

  private filterObjectivesByCategory() {
    const { objectiveCategories } = this.filterStateService.snapshot();
    const filteredObjectives = objectiveCategories.length
      ? this.attackCampaignDataList[0].objectives.filter((objective) =>
          objectiveCategories.includes(objective.category)
        )
      : this.attackCampaignDataList[0].objectives;

    this.objectives = filteredObjectives.map(
      (objective) => new AttackCampaignDataObjective(objective)
    );
  }

  private filterObjectivesByTeams() {
    const { teams } = this.filterStateService.snapshot();
    this.objectives.forEach(
      (objective) =>
        (objective.teams = objective.teams.filter((team) => teams.includes(team.teamId)))
    );
  }

  fetchCampaignData() {
    if (!this.exercise) return;

    const { campaignPhase } = this.filterStateService.snapshot();
    this.attackCampaignService
      .getData(this.exercise.id, campaignPhase)
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe((entries) => {
        this.attackCampaignDataList = this.processCampaignData(entries, campaignPhase);
        this.filterObjectives();
      });
  }

  processCampaignData(entries: AttackCampaignData[], campaignPhase: string): AttackCampaignData[] {
    const teamsCount = this.exercise.blueTeams.length;
    entries.forEach((entry) => {
      entry.objectives = entry.objectives.map((objective) => {
        objective.teams = objective.teams.map((team) => {
          // NOT_COMPROMISED is default status
          team.objectiveStatus = team.objectiveStatus ? team.objectiveStatus : 'NOT_COMPROMISED';
          return team;
        });
        objective.teams.length = teamsCount;
        return new AttackCampaignDataObjective({
          ...objective,
          trackBy: objective.name + campaignPhase,
        });
      });
      return entry;
    });
    return entries;
  }

  openStatusReport(
    team: AttackCampaignDataObjectiveTeam,
    objective: AttackCampaignDataObjective
  ): void {
    this.statusReportDialogRef = this.dialog.open(ObjectiveStatusReportComponent, {
      disableClose: true,
      data: {
        team: team,
        objective: objective,
        exercise: this.exercise,
      },
    });

    this.statusReportDialogRef.afterClosed().subscribe((report: AttackCampaignReport) => {
      if (report) {
        const currentDataTeam = this.getTeam(objective.name, team.teamId);
        AttackCampaignComponent.applyReportToObjectiveTeam(report, currentDataTeam);
      }
      this.statusReportDialogRef = null;
    });
  }

  openReassignDialog(
    team: AttackCampaignDataObjectiveTeam,
    objective: AttackCampaignDataObjective
  ): void {
    this.assignDialogRef = this.dialog.open(UserAssignmentDialogComponent, {
      disableClose: false,
      data: {
        team: team,
        objective: objective,
        exerciseId: this.exercise.id,
      },
    });

    this.assignDialogRef.afterClosed().subscribe((result: AssignmentResult) => {
      const currentDataTeam = this.getTeam(objective.name, team.teamId);
      if (result?.assignment) {
        currentDataTeam.objectiveUserName = this.currentUser.username;
        currentDataTeam.userAssignmentId = result.assignment.id;
      }
      this.assignDialogRef = null;
    });
  }

  private getTeam(objectiveName: string, teamId: string): AttackCampaignDataObjectiveTeam {
    const currentDataObjective = this.objectives.find((o) => o.name === objectiveName);
    return currentDataObjective.teams.find((t) => t.teamId === teamId);
  }

  assignObjective(team: AttackCampaignDataObjectiveTeam) {
    if (!team.objectiveUserName) {
      const assignUserToObjective: UserAssignment = new UserAssignment({
        exerciseId: this.exercise.id,
        teamId: team.teamId,
        objectiveContainerId: team.objectiveContainerId,
        objectiveId: team.objectiveId,
      });
      this.attackCampaignService
        .createUserAssignment(assignUserToObjective)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          assignUserToObjective.id = res.assignmentId;
          this.notificationsService.success('ui.assigned');
          this.fetchCampaignData();
        });
    }
  }

  assignAllObjectiveTeams(objective: AttackCampaignDataObjective) {
    this.multiAssignDialogRef = this.dialog.open<MultiAssignDialogComponent, MultiAssignInput>(
      MultiAssignDialogComponent,
      {
        disableClose: false,
        data: {
          objective,
          exerciseId: this.exercise.id,
          currentUsername: this.currentUser.username,
        },
      }
    );

    this.multiAssignDialogRef.afterClosed().subscribe((result: MultiAssignResult) => {
      const allTeamObjectives = this.getAllTeamObjectives();
      if (result) {
        result.assignments.forEach((assignment) => {
          const assignmentObjective = allTeamObjectives.find(
            (teamObjective) => teamObjective.objectiveId === assignment.objectiveId
          );
          if (assignmentObjective) {
            assignmentObjective.objectiveUserName = this.currentUser.username;
            assignmentObjective.userAssignmentId = assignment.id;
          }
        });
      }

      this.multiAssignDialogRef = null;
    });
  }

  openBatchStatusReport(objective: AttackCampaignDataObjective) {
    this.multiReportDialogRef = this.dialog.open<MultiReportDialogComponent, MultiReportInput>(
      MultiReportDialogComponent,
      {
        disableClose: true,
        data: {
          objective,
          exerciseId: this.exercise.id,
          currentUsername: this.currentUser.username,
        },
      }
    );

    this.multiReportDialogRef.afterClosed().subscribe((result: MultiReportResult) => {
      if (result && result.reports && result.reports.length > 0) {
        const allTeamObjectives = this.getAllTeamObjectives();
        result.reports.forEach((report) => {
          const reportObjective = allTeamObjectives.find(
            (teamObjective) => teamObjective.objectiveId === report.objectiveId
          );
          if (reportObjective) {
            AttackCampaignComponent.applyReportToObjectiveTeam(report, reportObjective);
          }
        });
        this.multiReportDialogRef = null;
      }
    });
  }

  reassignObjective(team: AttackCampaignDataObjectiveTeam, objective: AttackCampaignDataObjective) {
    if (team.objectiveUserName && team.objectiveUserName !== this.currentUser.username) {
      this.openReassignDialog(team, objective);
    }
  }

  unassignObjective(team: AttackCampaignDataObjectiveTeam) {
    if (team.objectiveUserName === this.currentUser.username) {
      this.attackCampaignService
        .deleteUserAssignment(this.exercise.id, team.objectiveId, team.userAssignmentId)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationsService.success('ui.unassigned');
          this.fetchCampaignData();
        });
    }
  }

  isTeamAssignedToCurrentUser(team: AttackCampaignDataObjectiveTeam): boolean {
    return this.currentUser && team.objectiveUserName === this.currentUser.username;
  }

  isAnyObjectiveAssignedToCurrentUser(objective: AttackCampaignDataObjective): boolean {
    return (
      this.currentUser &&
      objective.teams.some((t) => t.objectiveUserName === this.currentUser.username)
    );
  }

  isEveryObjectiveAssignedToCurrentUser(objective: AttackCampaignDataObjective): boolean {
    return (
      this.currentUser &&
      objective.teams.every((t) => t.objectiveUserName === this.currentUser.username)
    );
  }

  getUserClass(team: AttackCampaignDataObjectiveTeam): string {
    if (this.currentUser && team.objectiveUserName === this.currentUser.username) {
      return 'fa-user-times';
    }
    return team.objectiveUserName == null ? 'fa-user-plus' : 'fa-user';
  }

  private getAllTeamObjectives(): AttackCampaignDataObjectiveTeam[] {
    return this.objectives.reduce<AttackCampaignDataObjectiveTeam[]>(
      (objectives, currObjective) => objectives.concat(currObjective.teams),
      []
    );
  }

  private static calculateStatus(report: AttackCampaignReport): ObjectiveStatus {
    if (report.status === OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE) {
      return OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE;
    } else {
      return OBJECTIVE_STATUS.PENDING_COMPROMISED;
    }
  }

  private static applyReportToObjectiveTeam(
    report: AttackCampaignReport,
    team: AttackCampaignDataObjectiveTeam
  ) {
    team.objectiveStatus = AttackCampaignComponent.calculateStatus(report);
    team.isAttackAttempted = true;
    team.reportCount++;
  }
}
