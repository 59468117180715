import { Exercise, ExerciseType } from '../models/gamenet/exercise.model';
import { IsaModule } from '../models/shared/isa-module.model';
import { ObservableView, ObserverViewType } from '../models/shared/settings.model';
import { ADMIN_PERMISSIONS, EXERCISE_PERMISSIONS } from './permissions';

const CAMPAIGN_HYBRID_VERSUS = [ExerciseType.CAMPAIGN, ExerciseType.HYBRID, ExerciseType.VERSUS];
const CTF_HYBRID_VERSUS = [ExerciseType.CTF, ExerciseType.HYBRID, ExerciseType.VERSUS];
const ALL_TYPES = [
  ExerciseType.CAMPAIGN,
  ExerciseType.HYBRID,
  ExerciseType.CTF,
  ExerciseType.UNPUBLISHED,
  ExerciseType.VERSUS,
];
const CAMPAIGN_CTF_HYBRID_VERSUS = [
  ExerciseType.CAMPAIGN,
  ExerciseType.CTF,
  ExerciseType.HYBRID,
  ExerciseType.VERSUS,
];

const CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS = [
  ExerciseType.CAMPAIGN,
  ExerciseType.UNPUBLISHED,
  ExerciseType.HYBRID,
  ExerciseType.VERSUS,
];
const CAMPAIGN_HYBRID = [ExerciseType.CAMPAIGN, ExerciseType.HYBRID];
const CTF_VERSUS = [ExerciseType.CTF, ExerciseType.VERSUS];

export const VIEWS_CONFIG = {
  GN: {
    BETA: {
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
        fromExperimentalFeatures: true,
      },
    },
    EXERCISE: {
      OVERVIEW_LIST: {
        path: 'exercise',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: ALL_TYPES,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: ALL_TYPES,
          },
          {
            permission: EXERCISE_PERMISSIONS.RED_TEAM,
            supportedExerciseTypes: ALL_TYPES,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: ALL_TYPES,
          },
          {
            permission: EXERCISE_PERMISSIONS.WAITING_ROOM,
            supportedExerciseTypes: [ExerciseType.CTF, ExerciseType.HYBRID],
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      DEFINITION: {
        path: 'exercise-definition',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: ALL_TYPES,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
    },
    SCORING: {
      ADVANCED_SCORING_LOG: {
        path: 'advanced-scoring-log',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      SCORING_LOG: {
        path: 'scoring-log',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      TEAM_SCORING: {
        path: 'team-scoring',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.RED_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          isTeamScoring: true,
        },
      },
      USER_SCORING: {
        path: 'user-scoring',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          requireTeamAssessment: true,
        },
      },
      SUMMARY_TIMELINE: {
        path: 'summary-timeline',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      TEAM_TIMELINE: {
        path: 'team-timeline',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
    },
    REPORTING: {
      INCIDENT_REPORT: {
        path: 'incident-report',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      SITUATION_REPORT: {
        path: 'situation-report',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      ATTACK_CAMPAIGN: {
        path: 'attack-campaign',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.RED_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
    },
    JUDGING: {
      ATTACK_REPORTS: {
        path: 'attack-reports',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.RED_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      INCIDENT_REPORTS: {
        path: 'incident-reports',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      SITUATION_REPORTS: {
        path: 'situation-reports',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      TASK_REPORTS: {
        path: 'ctf/task-reports',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      SPECIAL_SCORING: {
        path: 'special-scoring',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
    },
    SITUATIONAL_AWARENESS: {
      TEAM_STATUS: {
        path: 'team-status',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      SEGMENTS_STATUS: {
        path: 'segments-status',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      TARGET_CHECKS_STATUS: {
        path: 'target-checks-status',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.RED_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      REPORTING_STATUS: {
        path: 'reporting-status',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
          {
            permission: EXERCISE_PERMISSIONS.RED_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      TEAM_AWARENESS: {
        path: 'team-awareness',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      CAMPAIGN_LIVE: {
        path: 'campaign-live',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          isCampaignLive: true,
        },
      },
      SMART_CITY: {
        path: 'smart-city',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: ALL_TYPES,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: ALL_TYPES,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          isCampaignLive: true,
          fromExperimentalFeatures: true,
        },
      },
      CAMPAIGN_LIVE__OBSERVER: {
        path: 'campaign-live/observe',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.OBSERVER,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          isObserver: true,
          observableViewType: ObserverViewType.CAMPAIGN_LIVE,
        },
      },
      TEAM_RANKING: {
        path: 'team-ranking',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CAMPAIGN_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      NEWS_INJECT: {
        OVERVIEW_LIST: {
          path: 'news-injects',
          permissions: [
            {
              permission: EXERCISE_PERMISSIONS.ADMIN,
              supportedExerciseTypes: ALL_TYPES,
            },
            {
              permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
              supportedExerciseTypes: ALL_TYPES,
            },
            {
              permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
              supportedExerciseTypes: ALL_TYPES,
            },
          ],
          prerequisites: {
            modulesEnabled: [IsaModule.GAMENET],
          },
        },
      },
    },
    CTF: {
      MISSION_BOARD: {
        path: 'ctf/mission-board',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WAITING_ROOM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      TASKS_STATUS_OVERVIEW: {
        path: 'ctf/tasks-status-overview',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          fromExperimentalFeatures: true,
        },
      },
      LEADER_BOARD: {
        path: 'ctf/leaderboard',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      GAME_OVERVIEW: {
        path: 'ctf/overview',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      PODIUM: {
        path: 'ctf/podium',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      PODIUM__OBSERVER: {
        path: 'ctf/podium/observe',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
          {
            permission: EXERCISE_PERMISSIONS.OBSERVER,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          isObserver: true,
          observableViewType: ObserverViewType.PODIUM,
        },
      },
      ASSESSMENT_REPORT: {
        path: 'ctf/assessment-report',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isIndividualAssessment;
            },
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isIndividualAssessment;
            },
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isIndividualAssessment;
            },
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      NICE_SUMMARY: {
        path: 'ctf/nice-summary',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: [ExerciseType.CTF],
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isNiceFrameworkSupported;
            },
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: [ExerciseType.CTF],
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isNiceFrameworkSupported;
            },
          },
          {
            permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
            supportedExerciseTypes: [ExerciseType.CTF],
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isNiceFrameworkSupported;
            },
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
        },
      },
      NICE_LEADERBOARD: {
        path: 'ctf/nice-leaderboard',
        permissions: [
          {
            permission: EXERCISE_PERMISSIONS.ADMIN,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isNiceFrameworkSupported;
            },
          },
          {
            permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
            supportedExerciseTypes: CTF_HYBRID_VERSUS,
            additionalExerciseFilter: (exercise: Exercise) => {
              return !!exercise && exercise.isNiceFrameworkSupported;
            },
          },
        ],
        prerequisites: {
          modulesEnabled: [IsaModule.GAMENET],
          fromExperimentalFeatures: true,
        },
      },
    },
    WAITING_ROOM: {
      path: 'waiting-room',
      permissions: [
        {
          permission: EXERCISE_PERMISSIONS.WAITING_ROOM,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
      ],
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
      },
    },
    WAITING_ROOM_STATISTICS: {
      path: 'statistics',
      permissions: [
        {
          permission: EXERCISE_PERMISSIONS.ADMIN,
          supportedExerciseTypes: ALL_TYPES,
        },
        {
          permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
          supportedExerciseTypes: ALL_TYPES,
        },
      ],
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
      },
    },
    MANUAL_TEAM_ASSIGNMENT: {
      path: 'team-assignment',
      permissions: [
        {
          permission: EXERCISE_PERMISSIONS.ADMIN,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
        {
          permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
      ],
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
      },
    },
    VSPHERE_CONSOLE: {
      path: 'vconsole',
      permissions: [
        {
          permission: EXERCISE_PERMISSIONS.ADMIN,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
        {
          permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
        {
          permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
      ],
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
      },
    },
    GUACAMOLE_CONSOLE: {
      path: 'gconsole',
      permissions: [
        {
          permission: EXERCISE_PERMISSIONS.ADMIN,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
        {
          permission: EXERCISE_PERMISSIONS.BLUE_TEAM,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
        {
          permission: EXERCISE_PERMISSIONS.WHITE_TEAM,
          supportedExerciseTypes: CAMPAIGN_CTF_HYBRID_VERSUS,
        },
      ],
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
      },
    },
  },
  ADMIN: {
    SETTINGS: {
      path: 'settings',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_SETTINGS }],
    },
    PRODUCT_KEY: {
      path: 'product-key',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_PRODUCT_KEY }],
    },
    USERS: {
      path: 'users',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_USERS }],
    },
    INTEGRATIONS: {
      path: 'integrations',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_INTEGRATIONS }],
    },
    SERVICES_HEALTH_OVERVIEW: {
      path: 'services-health-overview',
      permissions: [{ permission: ADMIN_PERMISSIONS.VIEW_HEALTH_DATA }],
    },
    GMA_OVERVIEW: {
      path: 'gma-overview',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_GMA }],
      prerequisites: {
        modulesEnabled: [IsaModule.GAMENET],
      },
    },
    FILE_MANAGER: {
      path: 'file-manager',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_FILES }],
    },
    CUSTOM_VARIABLES: {
      path: 'custom-variables',
      permissions: [{ permission: ADMIN_PERMISSIONS.MANAGE_CUSTOM_VARIABLES }],
    },
  },
};

export const OBSERVABLE_VIEWS = [
  new ObservableView({
    type: ObserverViewType.CAMPAIGN_LIVE,
    name: 'Campaign live',
    path: '/app/gamenet/campaign-live/observe',
    supportedExerciseTypes: CAMPAIGN_UNPUBLISHED_HYBRID_VERSUS,
  }),
  new ObservableView({
    type: ObserverViewType.PODIUM,
    name: 'Podium',
    path: '/app/gamenet/ctf/podium/observe',
    supportedExerciseTypes: [
      ExerciseType.CTF,
      ExerciseType.HYBRID,
      ExerciseType.UNPUBLISHED,
      ExerciseType.VERSUS,
    ],
  }),
];
