import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ActiveConnection,
  ConsoleConnectionParameters,
  ConsoleConnectionType,
  GuacamoleManagerBaseService,
  NotificationsService,
  VsphereManagerBaseService,
} from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  BlueTeam,
  Exercise,
  ExerciseStatus,
  GroupedUserData,
  NetworkSegment,
  TargetPowerState,
  TargetStatusSummary,
  User,
  UserListItem,
} from '../../../models';
import {
  AuthenticationService,
  ExerciseService,
  GuacamoleManagerService,
  IntervalService,
  TargetStatusService,
  VirtualMachineService,
  VsphereManagerService,
} from '../../../services';
import {
  EXERCISE_PERMISSIONS,
  FilterStateModel,
  FilterStateService,
  INCIDENT_TYPE,
  RoleCode,
  ROLES,
  TARGET_STATUS,
} from '../../../shared';
import { ConsoleBaseComponent } from '../console-base/console-base.component';
import {
  TargetAssignmentDialogComponent,
  TargetAssignmentDialogInput,
} from './target-assignment-dialog/target-assignment-dialog.component';
import { TargetRevertToSnapshotDialogComponent } from './target-revert-to-snapshot-dialog/target-revert-to-snapshot-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-target-status',
  templateUrl: './target-status.component.html',
  styleUrls: ['./target-status.component.scss'],
  providers: [
    { provide: GuacamoleManagerBaseService, useClass: GuacamoleManagerService },
    { provide: VsphereManagerBaseService, useClass: VsphereManagerService },
  ],
})
export class TargetStatusComponent extends ConsoleBaseComponent implements OnInit {
  private static UNASSIGNED = 'UNASSIGNED';

  loadData$: Observable<Partial<FilterStateModel>>;
  filterData$: Observable<Partial<FilterStateModel>>;

  teams: BlueTeam[] = [];
  groupedUsers: GroupedUserData[] = [];
  exerciseId: string;
  networkSegments: NetworkSegment[] = [];
  filteredTargetStatusDataItems: TargetStatusSummary[] = [];
  dataLoaded: boolean;
  ROLES = ROLES;
  statuses: string[] = [TARGET_STATUS.GOOD, TARGET_STATUS.COMPROMISED, TARGET_STATUS.NOT_AVAILABLE];
  userRole: RoleCode;
  currentUser: User;
  targetAssignmentDialogRef: MatDialogRef<TargetAssignmentDialogComponent>;
  targetRevertToSnapshotDialogRef: MatDialogRef<TargetRevertToSnapshotDialogComponent>;
  lastUpdatedTimestamp: Date;
  targetStatusSummaryList: TargetStatusSummary[] = [];
  revertInProgressEvent: { revertInProgress: boolean; targetId: string };
  consoleConnectionType: ConsoleConnectionType;
  isGuacamoleConsoleLoginEnabled = false;
  target: TargetStatusSummary;
  isExerciseRunning: boolean = false;

  readonly TargetPowerState = TargetPowerState;
  readonly ConsoleConnectionType = ConsoleConnectionType;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;

  constructor(
    private exerciseService: ExerciseService,
    private targetStatusService: TargetStatusService,
    private authenticationService: AuthenticationService,
    private intervalService: IntervalService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    protected notificationsService: NotificationsService,
    protected virtualMachineService: VirtualMachineService,
    public filterStateService: FilterStateService
  ) {
    super(virtualMachineService, notificationsService);
  }

  ngOnInit(): void {
    this.loadData$ = this.filterStateService.filter$('team', 'networkSegment');
    this.filterData$ = this.filterStateService.filter$('targetStatuses', 'user', 'assignedToMe');
    this.currentUser = this.authenticationService.currentUser;
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.userRole = this.authenticationService.getRole(exercise.id);
        this.exerciseId = exercise.id;
        this.teams = exercise.blueTeams;
        this.networkSegments = exercise.networkSegments;
        this.isTargetManagementEnabled = exercise.isTargetManagementEnabled;
        this.targetStatusSummaryList = [];
        this.isGuacamoleConsoleLoginEnabled = exercise.isGuacamoleConsoleLoginEnabled;

        if (this.userRole === this.ROLES.BLUE) {
          this.exerciseService
            .getUserBlueTeam(exercise.id)
            .pipe(untilDestroyed(this))
            .subscribe((userBlueTeamId) => {
              this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
            });
        }

        this.intervalService
          .getWidgetRefreshInterval()
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.loadTargetStatusData();
            this.updateIsExerciseRunning();
          });
      });

    this.loadData$.pipe(untilDestroyed(this)).subscribe(() => this.loadTargetStatusData());
    this.filterData$.pipe(untilDestroyed(this)).subscribe(() => this.applyFilter());
  }

  loadTargetStatusData(): void {
    const { team, networkSegment } = this.filterStateService.snapshot();
    this.dataLoaded = false;
    if (team && this.exerciseId) {
      this.targetStatusService
        .getTargetStatusData(this.exerciseId, team, networkSegment)
        .pipe(untilDestroyed(this))
        .subscribe((targetStatusSummaryList) => {
          this.targetStatusSummaryList = targetStatusSummaryList;
          this.applyFilter();
          this.initUsers(team);
          this.lastUpdatedTimestamp = new Date();
          this.dataLoaded = true;
        });
    }
  }

  private updateIsExerciseRunning(): void {
    this.exerciseService
      .getExerciseStatus(this.exerciseId)
      .pipe(untilDestroyed(this))
      .subscribe((exerciseStatus) => {
        this.isExerciseRunning = exerciseStatus.status === ExerciseStatus.RUNNING;
      });
  }

  private applyFilter() {
    const { assignedToMe, targetStatuses, user } = this.filterStateService.snapshot();
    this.targetStatusSummaryList = this.targetStatusSummaryList.sort(
      (a, b) => a.targetChecks.length - b.targetChecks.length
    );

    if (targetStatuses.length === 0 && !user && !assignedToMe) {
      this.filteredTargetStatusDataItems = this.targetStatusSummaryList;
      return;
    }

    this.filteredTargetStatusDataItems = this.targetStatusSummaryList
      .map((ts) => new TargetStatusSummary(ts))
      .filter((targetData) => {
        targetData.targetChecks = targetData.targetChecks.filter((targetCheckData) => {
          const matchesStatusFilter =
            targetStatuses.length > 0
              ? targetStatuses.indexOf(targetCheckData.status) !== -1
              : true;
          const matchesUserFilter = user
            ? user === TargetStatusComponent.UNASSIGNED
              ? !targetCheckData.username
              : targetCheckData.username === user
            : true;
          const matchesOnlyAssignedToMe = assignedToMe
            ? targetCheckData.username === this.currentUser.username
            : true;

          return matchesStatusFilter && matchesUserFilter && matchesOnlyAssignedToMe;
        });

        return targetData.targetChecks.length > 0;
      });
  }

  get unassigned(): string {
    return TargetStatusComponent.UNASSIGNED;
  }

  initUsers(teamId: string): void {
    if (teamId) {
      this.exerciseService
        .getExerciseBlueTeamUsers(this.exerciseId, teamId)
        .pipe(untilDestroyed(this))
        .subscribe((users) => {
          const fetchedUsers = this.getUsersGroupedByLoginOrigin(users);
          const fetchedUsernames = fetchedUsers.map((it) => it.username);
          const usersFromTargetChecks = this.getUsersFromTargetChecks().filter(
            (it) => fetchedUsernames.indexOf(it.username) === -1
          );

          this.groupedUsers = fetchedUsers
            .concat(usersFromTargetChecks)
            .sort((a, b) => (a.username < b.username ? -1 : 1));
        });
    }
  }

  private getUsersGroupedByLoginOrigin(users: UserListItem[]): GroupedUserData[] {
    const usernameToLoginOriginsMap = users.reduce((result, currentUser) => {
      const existingUserOrigins = (result[currentUser.username] =
        result[currentUser.username] || []);
      if (currentUser.origin != null && existingUserOrigins.indexOf(currentUser.origin) === -1) {
        existingUserOrigins.push(currentUser.origin);
      }
      return result;
    }, {});

    return Object.keys(usernameToLoginOriginsMap).map(
      (username) => new GroupedUserData(username, false, usernameToLoginOriginsMap[username])
    );
  }

  private getUsersFromTargetChecks(): GroupedUserData[] {
    return this.targetStatusSummaryList
      .map((it) => it.targetChecks.map((tc) => tc.username))
      .reduce((resultArray, currentArray) => resultArray.concat(currentArray), [])
      .filter(
        (username, index, self) =>
          username != null && username.trim() !== '' && self.indexOf(username) === index
      )
      .map((username) => new GroupedUserData(username, true));
  }

  //TODO refactor incident-report with state, remove teamId
  openReportPageOnCompromisedTarget(targetCheckId: string): void {
    const { team } = this.filterStateService.snapshot();
    this.router.navigate(['/app/gamenet/incident-report'], {
      queryParams: {
        teamId: team,
        targetCheckId: targetCheckId,
        incidentType: INCIDENT_TYPE.NOT_COMPROMISED,
      },
    });
  }

  openTargetAssignmentDialog(target: TargetStatusSummary) {
    const { team } = this.filterStateService.snapshot();
    this.targetAssignmentDialogRef = this.dialog.open<
      TargetAssignmentDialogComponent,
      TargetAssignmentDialogInput
    >(TargetAssignmentDialogComponent, {
      disableClose: false,
      data: {
        exerciseId: this.exerciseId,
        target: target,
        username: this.currentUser.username,
        teamId: team,
        groupedUsers: this.groupedUsers,
      },
    });

    this.targetAssignmentDialogRef.afterClosed().subscribe(() => {
      this.loadTargetStatusData();
    });
  }

  openTargetRevertToSnapshotDialog(target: TargetStatusSummary) {
    const { team } = this.filterStateService.snapshot();
    this.targetRevertToSnapshotDialogRef = this.dialog.open(TargetRevertToSnapshotDialogComponent, {
      disableClose: true,
      data: {
        exerciseId: this.exerciseId,
        teamId: team,
        target: target,
      },
    });

    this.targetRevertToSnapshotDialogRef.componentInstance.revertInProgressEvent
      .pipe(untilDestroyed(this))
      .subscribe((revertInProgressEvent) => {
        this.revertInProgressEvent = revertInProgressEvent;
      });

    this.targetRevertToSnapshotDialogRef.afterClosed().subscribe(() => {
      this.loadTargetStatusData();
    });
  }

  openConsole(target: TargetStatusSummary, connectionType: ConsoleConnectionType) {
    this.target = target;
    const { team } = this.filterStateService.snapshot();
    super.openConsole(target, connectionType, this.exerciseId, team);
  }

  forceRefreshTargets() {
    this.virtualMachineService
      .forceRefreshTargets(this.exerciseId)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.notificationsService.info('ui.targetStatus.targetsRefreshInProgress'));
  }

  restoreConsoleConnection(activeConnection: ActiveConnection) {
    this.consoleConnectionParams = new ConsoleConnectionParameters({
      id: activeConnection.id,
      name: activeConnection.name,
      connectionType: activeConnection.protocol,
      port: activeConnection.port,
      username: activeConnection.username,
      password: activeConnection.password,
      source: activeConnection.source,
    });

    this.isGuacamoleConsoleOpened = true;
    this.openGuacamoleConsole.emit(true);
  }

  isVmTargetExists(targetStatusSummary: TargetStatusSummary): boolean {
    return this.isTargetManagementEnabled && !targetStatusSummary.vmId;
  }

  trackByFn = (index) => index;
}
